/* eslint-disable no-self-compare */
/* eslint-disable react/jsx-props-no-spreading, no-debugger */
import { useState, useContext } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { Button } from '../Global/Button';
import { DateRangePicker } from '../Global/DateRangePicker';
import { Typeahead } from '../Global/Typeahead';
import FormDataContext from '../../FormDataContext';
import { pageView } from '../../utils/pageView';

export const SearchPanel = () => {
  const { formData, setFormData } = useContext(FormDataContext);
  const [, setSearchParams] = useSearchParams();
  const [depart, setDepart] = useState(null);
  const [arrive, setArrive] = useState(null);
  const [players, setPlayers] = useState(null);
  const timeZone = 'America/New_York';
  const [typeAheadValue, setTypeAheadValue] = useState('');
  const [formError, setFormError] = useState(false);

  const navigate = useNavigate();

  const handleTypeAheadChange = (newValue) => {
    setTypeAheadValue(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line

    if (arrive !== undefined || arrive !== 'Invalid Date') {
      setArrive(arrive);
    } else {
      setArrive(formData.arrive);
    }

    if (depart !== undefined || depart !== 'Invalid Date') {
      setDepart(depart);
    } else {
      setDepart(formData.depart);
    }

    const updatedFormData = {
      lat: formData.lat,
      long: formData.long,
      proximity: 50,
      q: 'geo-location',
      location: formData.location,
      players,
      'sort-by': 'Facilities.Rating',
      'sort-direction': 'desc',
      'date-max': depart,
      'date-min': arrive,
      take: 15,
      'trade-only': true,
      expand: 'Facilities.RatingSummary|Facilities',
    };

    const isValid = Object.values(updatedFormData).every((value) => value);

    if (isValid) {
      setFormData(updatedFormData);
      setSearchParams(updatedFormData);
      pageView('Initial Search', {
        ...updatedFormData,
        'date-min': formatInTimeZone(arrive, timeZone, 'yyyy-MM-dd'),
        'date-max': formatInTimeZone(depart, timeZone, 'yyyy-MM-dd'),
      });

      navigate({
        pathname: '/results',
        search: `?${createSearchParams(updatedFormData)}`,
      });
    } else {
      setFormError(true);
    }
  };

  return (
    <div className="flex justify-center w-full mx-auto md:block sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-w-screen-2xl">
      <div className="z-20 inline-block p-8 bg-white rounded-lg shadow-lg w-80 sm:w-96 md:w-128">
        <div className="mb-6">
          <h2 className="mb-2 text-4xl font-semibold text-gp-black">Plan your next golf trip</h2>
          <p className="leading-snug text-gp-black">
            Save when you package your first round, travel, and accommodations.
          </p>
        </div>
        <form id="bookseats-search" className="mb-10" onSubmit={handleSubmit}>
          <div className="flex items-center p-2 mb-4 border border-gray-300 rounded-sm">
            <img className="h-6" src={`${window.globals.publicPath}/images/location-icon.svg`} alt="" />
            <Typeahead value={typeAheadValue} handleTypeAheadChange={handleTypeAheadChange} />
          </div>

          <div className="flex items-center w-full px-2 py-2 mb-4 border border-gray-300 rounded-sm md:flex">
            <img className="h-6" src={`${window.globals.publicPath}/images/cal-icon.svg`} alt="" />
            <DateRangePicker setArrive={setArrive} setDepart={setDepart} />
          </div>
          <div className="flex items-center p-2 mb-4 border border-gray-300 rounded-sm">
            <img className="h-6" src={`${window.globals.publicPath}/images/golftee-icon.svg`} alt="" />
            <select
              className="w-full border-none placeholder:text-slate-600 text-slate-500"
              id="players"
              name="players"
              defaultValue="Number of Players"
              onChange={(event) => setPlayers(event.target.value)}
            >
              <option value="">Number of Players</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </select>
          </div>
          <p className="text-xs text-gray-700">Please complete all fields in order to continue.</p>
          {formError && <p className="text-red-600">Please complete all fields</p>}
        </form>
        <Button type="submit" label="Search" href="/results" />
      </div>
    </div>
  );
};

export default SearchPanel;
