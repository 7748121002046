import { useState } from 'react';
import { Title } from '../../Global/Title';

const players = [1, 2, 3, 4];

export const PlayerSelector = ({ handlePlayerChange, player }) => {
  const [isActiveIndex, setIsActiveIndex] = useState(player);
  const active = (playerArg) => {
    setIsActiveIndex(playerArg);
    handlePlayerChange(playerArg);
  };

  return (
    <div className="pt-5 pb-7">
      <Title label="Players" />

      <span className="relative z-0 inline-flex w-full rounded-md shadow-sm">
        {players.map((playerArg) => {
          return (
            <button
              type="button"
              key={playerArg}
              className={`relative inline-flex items-center px-6 py-3 text-sm font-medium text-white first:rounded-tl-md first:rounded-bl-md last:rounded-tr-md last:rounded-br-md border border-gray-700 w-1/4 justify-center
                ${isActiveIndex === playerArg ? 'bg-gp-blue ' : 'bg-gray-800 text-sm hover:bg-gp-blue '}`}
              onClick={() => active(playerArg)}
            >
              {playerArg}
            </button>
          );
        })}
      </span>
    </div>
  );
};

export default PlayerSelector;
