/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useEffect, useRef, useContext } from 'react';
import { XIcon } from '@heroicons/react/solid';
import { uniq, union, groupBy } from 'lodash';
import { getTypeahead } from '../../services/provider';
import FormDataContext from '../../FormDataContext';

export const Typeahead = ({ value, handleTypeAheadChange }) => {
  const { setFormData } = useContext(FormDataContext);

  const [filteredData, setFilteredData] = useState([]);
  const [inputText, setInputText] = useState('');
  const clickAway = useRef(null);

  useEffect(() => {
    setInputText(value);
  }, [value]);

  const handleFilter = (event) => {
    const search = event.target.value;

    setInputText(search);
    getTypeahead({ searchKey: search }).then((response) => {
      const grouped = groupBy(response.hits, (hit) => {
        return hit.type;
      });

      const cities = grouped.city || [];
      const postals = grouped.postal || [];
      const places = grouped.place || [];

      const uniqueHits = uniq(union(cities, places, postals), (item) => {
        return item;
      });

      const sorted = uniqueHits.sort((a, b) => b.contextInformation.score - a.contextInformation.score);

      const newFilter = sorted.filter(({ displayName }) => {
        return displayName.indexOf(search.toLowerCase());
      });

      if (search === '') {
        setFilteredData([]);
      } else {
        setFilteredData(newFilter);
      }
    });
  };

  const clearInput = () => {
    setFilteredData([]);
    setInputText('');
  };

  const setSelected = (option, lat, lon) => {
    setFormData({ lat, long: lon, location: option });
    handleTypeAheadChange(option, lat, lon);
    setInputText(option);
    setFilteredData([]);
  };

  const handleClickAway = (event) => {
    const { current: click } = clickAway;
    if (click && !click.contains(event.target)) {
      setFilteredData([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickAway);

    return () => {
      document.removeEventListener('mousedown', handleClickAway);
    };
  }, []);

  return (
    <div className="relative w-full" ref={clickAway}>
      <div className="flex items-center justify-between">
        <input
          className="w-full border-none placeholder:text-slate-500"
          type="text"
          placeholder="Where to?"
          value={inputText}
          onChange={handleFilter}
        />
        <div className="searchIcon">
          {filteredData.length === 0 ? '' : <XIcon className="h-6 ml-4" id="clearBtn" onClick={clearInput} />}
        </div>
      </div>
      {filteredData.length !== 0 && (
        <div className="absolute z-30 h-56 pt-4 overflow-auto bg-white border-gray-200 shadow-lg">
          {filteredData.map((option) => {
            return (
              <p
                className="px-4 py-1 mb-2 cursor-pointer hover:bg-gray-200 text-gp-med-black"
                tabIndex="0"
                key={option.id}
                onClick={() => setSelected(option.displayName, option.geo.lat, option.geo.lon)}
              >
                {option.displayName}
              </p>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Typeahead;
