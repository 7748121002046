/* eslint-disable import/no-cycle */
import { ViewMoreModal } from '../../Modal/ViewMoreModal';

export const TeeTimeViewMore = ({
  handleViewMore,
  viewMoreCount,
  result,
  fetchInvoiceData,
  viewMore,
  setViewMore,
  selectedDate,
  startDate,
  endDate,
}) => {
  return (
    <>
      <button
        type="button"
        onClick={handleViewMore}
        className="flex flex-col justify-center px-2 py-3 bg-gray-800 rounded-sm text-gp-blue"
      >
        <div className="text-base font-semibold leading-tight text-center">+{viewMoreCount}</div>
        <div className="text-sm leading-tight text-center">More</div>
      </button>
      <ViewMoreModal
        result={result}
        fetchInvoiceData={fetchInvoiceData}
        viewMore={viewMore}
        setViewMore={setViewMore}
        selectedDate={selectedDate}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
};

export default TeeTimeViewMore;
