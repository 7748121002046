import { useState, useMemo } from 'react';
import { Routes, Route } from 'react-router-dom';
import { SearchPage } from './pages/SearchPage';
import { TeeTimesPage } from './pages/TeeTimesPage';
import { NotFoundPage } from './pages/NotFoundPage';
// eslint-disable-next-line import/no-named-as-default
import FormDataContext from './FormDataContext';

import './styles/App.css';

const App = () => {
  const [formData, setFormData] = useState({});
  const value = useMemo(
    () => ({
      formData,
      setFormData,
    }),
    [formData],
  );

  return (
    <FormDataContext.Provider value={value}>
      <Routes>
        <Route path="/" element={<SearchPage value={value} />} />
        <Route path="/results" element={<TeeTimesPage value={value} />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </FormDataContext.Provider>
  );
};

export default App;
