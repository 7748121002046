import { Destinations } from '../components/Destinations/Destinations';
import { SearchPanel } from '../components/Search/SearchPanel';
import { Steps } from '../components/Steps/Steps';

export const SearchPage = () => {
  return (
    <section className="pb-20 bg-gp-black">
      <div
        className="flex items-center px-6 mb-6 bg-no-repeat bg-cover bg-search"
        style={{
          backgroundImage: `url(${window.globals.publicPath}/images/search-lede.jpg)`,
        }}
      >
        <SearchPanel />
      </div>

      <section className="container px-6 mx-auto mb-11">
        <h3 className="mb-4 text-2xl font-semibold text-white">
          <span className="text-bs-red">New </span>
          GolfPass Trip Planner
        </h3>
        <Steps />
      </section>

      <section className="container px-6 mx-auto">
        <h3 className="mb-4 text-2xl font-semibold text-white">Popular Destinations</h3>
        <Destinations />
      </section>
    </section>
  );
};

export default SearchPage;
