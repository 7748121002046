/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  main: [
    { name: 'Contact Us', href: 'https://www.golfpass.com/contact-us' },
    { name: 'Terms of Use', href: 'https://qa2.golf-pass.psdops.com/terms-of-use' },
    { name: 'FAQ', href: 'https://www.golfpass.com/faq' },
    { name: 'Help Center', href: 'https://www.golfpass.com/help-center' },
    { name: 'Ad Choices', href: 'https://www.nbcuniversal.com/privacy/cookies#accordionheader2' },
    {
      name: 'CA Notice',
      href: 'https://www.nbcuniversal.com/privacy/california-consumer-privacy-act?brandA=Golf&intake=Golf',
    },
    { name: 'Privacy Policy', href: 'https://www.nbcuniversal.com/privacy?brandA=Golf&intake=Golf' },
  ],
};

export const Footer = () => {
  return (
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} target="_blank" rel="noreferrer" className="text-base text-white">
                {item.name}
              </a>
            </div>
          ))}
        </nav>
        <div className="mt-8 flex justify-center space-x-6">
          <a
            className="text-base text-white"
            target="_blank"
            rel="noreferrer"
            href="https://www.nbcuniversal.com/privacy/notrtoo?brandA=Golf&intake=Golf%22"
          >
            Do Not Sell My Personal Information
          </a>
        </div>
        <p className="mt-8 text-center text-base text-white">
          &copy; Copyright {new Date().getFullYear()} GolfPass. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
