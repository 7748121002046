export const NotFoundPage = () => {
  return (
    <section className="flex flex-col min-h-full pt-32 pb-12 bg-white">
      <div className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex justify-center flex-shrink-0">
          <a href="/" className="inline-flex">
            <span className="sr-only">GolfPass</span>
            <img
              className="w-auto h-12"
              src="https://golf-pass.brightspotcdn.com/0a/60/58f20d4a487e9106f1281a5038e9/gp-primary-logo-rgb-pos.svg"
              alt=""
            />
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-3xl font-semibold text-gp-blue">404</p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found.</h1>
            <p className="mt-2 text-base text-gray-500">Sorry, we couldn’t find the page you’re looking for.</p>
            <div className="mt-6">
              <a href="/" className="text-base font-medium underline text-gp-blue">
                Go back home
                <span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default NotFoundPage;
