/* eslint-disable react/jsx-props-no-spreading, no-unused-expressions, react/jsx-no-duplicate-props */
import Slider from 'react-slick';
import { useEffect, useState } from 'react';
import { formatInTimeZone } from 'date-fns-tz';
import { Title } from '../../Global/Title';

const settings = {
  speed: 500,
  infinite: false,
  slide: 'button',
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        variableWidth: true,
      },
    },
  ],
};

export const DateCarousel = ({
  startDate,
  endDate,
  formData,
  handleDateCarouselChange,
  carouselDefault,
  teeTimeDates,
}) => {
  const [sliderRef, setSliderRef] = useState(null);
  const [dateRange, setDateRange] = useState([]);
  const [isActiveIndex, setIsActiveIndex] = useState(null);
  const dateArray = [];
  const timeZone = 'America/New_York';

  useEffect(() => {
    if (endDate !== null) {
      const startFormatted = formatInTimeZone(startDate, timeZone, 'yyyy-MM-dd');
      const endFormatted = formatInTimeZone(endDate, timeZone, 'yyyy-MM-dd');
      const end = new Date(endFormatted);
      let loop = new Date(startFormatted);
      while (loop <= end) {
        dateArray.push(loop);
        const newDate = loop.setDate(loop.getDate() + 1);
        loop = new Date(newDate);
      }
      setDateRange(dateArray);
      const firstTeeTimeDate = teeTimeDates.find((date) => {
        const formatStartDate = formatInTimeZone(startDate, timeZone, 'yyyy-MM-dd');
        const startDateNum = Number(formatStartDate.replace(/-/g, ''));
        return date !== startDateNum;
      });
      const formattedArray = [];
      dateArray.forEach((day, index) => {
        const dayFormatted = formatInTimeZone(day, timeZone, 'yyyy-MM-dd');
        formattedArray.push(dayFormatted);
        const removeHyphen = Number(dayFormatted.replace(/-/g, ''));
        if (removeHyphen === firstTeeTimeDate) {
          setIsActiveIndex(index);
          sliderRef?.slickGoTo(index);
        }
      });
    }
  }, [startDate, endDate, formData, carouselDefault]);

  const active = (date, index) => {
    const startFormatted = formatInTimeZone(date, timeZone, 'yyyy-MM-dd');
    const endFormatted = formatInTimeZone(date, timeZone, 'yyyy-MM-dd');
    setIsActiveIndex(index);
    handleDateCarouselChange(startFormatted, endFormatted, date);
  };

  return (
    <section>
      <Title label="Select Your Tee Time Date" />
      <Slider ref={setSliderRef} {...settings}>
        {dateRange.length > 0 &&
          dateRange.map((date, index) => {
            return (
              <button
                key={date}
                data-date={date}
                className={`relative inline-flex items-center px-4 py-2 text-sm font-medium text-white focus:z-10 focus:outline-none border-gray-800 ${
                  isActiveIndex === index ? ' bg-gp-blue rounded date-activated' : 'bg-gp-background-black'
                }`}
                onClick={() => active(date, index)}
                type="submit"
              >
                {formatInTimeZone(date, timeZone, 'EEE, MMM dd')}
              </button>
            );
          })}
      </Slider>
    </section>
  );
};

export default DateCarousel;
