import axios from 'axios';
// import { handleResponse, handleError } from './response';

const BASE_URL = `${window.globals.apiEndpoint}/api/v1/`;

export const getTeeTimes = async (params) => {
  const queryParams = new URLSearchParams(params);

  try {
    const response = await axios.get(`${BASE_URL}teetimes?${queryParams}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getTypeahead = async (params) => {
  if (!params.searchKey || params.searchKey.length < 3) {
    return [];
  }

  const queryParams = new URLSearchParams(params);

  try {
    const response = await axios.get(`${BASE_URL}geolocations/typeahead?${queryParams}`);
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getInvoice = async (params) => {
  const queryParams = new URLSearchParams(params);

  try {
    const response = await axios.get(`${BASE_URL}teetimes/teetimeinvoice?${queryParams}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const getLocation = async (params) => {
  const queryParams = new URLSearchParams(params);

  try {
    const response = await axios.get(`${BASE_URL}geolocations/reverse?${queryParams}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
