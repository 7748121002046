import { formatInTimeZone } from 'date-fns-tz';

export const CourseTile = ({ teeModal, result, player }) => {
  const toRegularTime = (militaryTime) => {
    const [hours, minutes] = militaryTime.split(':');
    return `${hours > 12 ? hours - 12 : hours}:${minutes}${hours >= 12 ? ' PM' : ' AM'}`;
  };
  const timeZone = 'America/New_York';

  return (
    <div className="relative">
      <div className="justify-between block mb-6 text-center sm:flex">
        <h3 className="text-2xl">
          Your golf trip to&nbsp;
          <span className="font-bold text-gp-blue">
            {result.Address.City}, {result.Address.StateProvinceCode}
          </span>
        </h3>
        <p>{formatInTimeZone(new Date(teeModal.Time), timeZone, 'MMM dd')}</p>
      </div>
      <div className="p-5 mb-4 space-y-4 text-center bg-gray-100 sm:p-0 sm:text-left sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
        <div className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7">
          <img className="mx-auto rounded-lg shadow-lg object-cover" src={result.FullSizeImagePath} alt="golf-course" />
        </div>
        <div className="sm:col-span-2">
          <div className="space-y-4">
            <div className="space-y-1 font-medium leading-6">
              <h3 className="mt-6 text-lg">{result.Name}</h3>
              <p className="text-gray-400">
                {result.Address.Line1}, {result.Address.City}, {result.Address.StateProvinceCode}
              </p>
            </div>
            <div>
              <p>{formatInTimeZone(new Date(teeModal.Time), timeZone, 'MMM dd, yyyy')}</p>
              <p>Tee Time - {toRegularTime(teeModal.Time.split('T')[1])}</p>
            </div>
            <div>
              <p className="mb-6">
                {player} {player > 1 ? 'Players' : 'Player'}
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="text-xs leading-snug text-gray-600">
        BookSeats.com is a website owned and operated by BookSeats Inc. See&nbsp;
        <a
          href="https://bookseats.com/terms"
          className="underline text-gp-blue"
          target="_blank"
          rel="noreferrer noopener"
        >
          Bookseats.com Terms and Conditions
        </a>
        &nbsp;for details. For additional terms, see&nbsp;
        <a
          href="https://www.golfpass.com/terms-and-conditions"
          className="underline text-gp-blue"
          target="_blank"
          rel="noreferrer noopener"
        >
          GolfPass Terms of Use
        </a>
        &nbsp;and&nbsp;
        <a
          href="https://www.golfnow.com/support/about-us/terms"
          className="underline text-gp-blue"
          target="_blank"
          rel="noreferrer noopener"
        >
          GolfNow Terms of Use
        </a>
        .
      </p>
    </div>
  );
};

export default CourseTile;
