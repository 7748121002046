import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

const people = [
  { id: 1, name: 'Closest', type: 'closest', data: 'Facilities.Distance', direction: 'asc' },
  { id: 2, name: 'Rating', type: 'rating', data: 'Facilities.Rating', direction: 'desc' },
  { id: 3, name: 'A to Z', type: 'az', data: 'Facilities.Name', direction: 'asc' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SortBy = ({ setSortByAll }) => {
  const [selected, setSelected] = useState(people[1].name);
  const setSelectedAll = (name, data, direction) => {
    setSelected(name);
    setSortByAll(data, direction);
  };

  return (
    <Listbox value={selected} onChange={(e) => setSelectedAll(e.name, e.data, e.direction)}>
      {({ open }) => (
        <div className="flex items-center lg:justify-end">
          <Listbox.Label className="block mr-2 text-sm font-semibold text-white cursor-pointer">
            Sort By:{' '}
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left text-white rounded-md shadow-sm cursor-default cursor-pointer bg-gp-background-black focus:outline-none focus:ring-1 focus:ring-gp-light-black focus:border-gp-light-black sm:text-sm">
              <span className="block">{selected}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base text-white rounded-md shadow-lg bg-gp-background-black max-h-60 focus:outline-none sm:text-sm">
                {people.map((person) => (
                  <Listbox.Option
                    key={person.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-gp-light-black cursor-pointer' : 'text-white',
                        'cursor-pinter select-none relative py-2 px-3',
                      )
                    }
                    value={person}
                  >
                    {/* eslint-disable-next-line no-shadow */}
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block')}>
                          {person.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 left-0 flex items-center pl-1.5',
                            )}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default SortBy;
