import { Distance } from './Distance';
import { PlayerSelector } from './PlayerSelector';
import { TimeRangeSlider } from '../../Global/TimeRangeSlider';
import { OneDateRangePicker } from '../../Global/OneDateRangePicker';
import { Typeahead } from '../../Global/Typeahead';
import { Title } from '../../Global/Title';

export const FilterColumn = ({
  startDate,
  endDate,
  typeAheadValue,
  handleDateChange,
  handleTimeChange,
  debouncedEventHandler,
  handlePlayerChange,
  handleTypeAheadChange,
  player,
  distanceVal,
}) => {
  return (
    <section className="justify-between lg:flex xl:block filter-column">
      <div className="lg:mr-8 xl:mr-0">
        <OneDateRangePicker handleDateChange={handleDateChange} startDate={startDate} endDate={endDate} />
        <hr className="border-gray-700" />
        <div className="pt-5 pb-7">
          <Title label="Location" />
          <Typeahead value={typeAheadValue} handleTypeAheadChange={handleTypeAheadChange} height="h-16" />
        </div>
      </div>
      <hr className="border-gray-700" />
      <div className="lg:mr-8 xl:mr-0">
        <TimeRangeSlider handleTimeChange={handleTimeChange} />
        <hr className="border-gray-700" />
        <Distance distanceVal={distanceVal} debouncedEventHandler={debouncedEventHandler} />
      </div>

      <hr className="border-gray-700" />
      <PlayerSelector player={player} handlePlayerChange={handlePlayerChange} />
    </section>
  );
};

export default FilterColumn;
