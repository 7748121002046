/* eslint-disable no-debugger, no-console, no-shadow,no-unused-expressions, array-callback-return, no-unused-vars, no-undef */
import axios from 'axios';

const defaultDestinations = [
  {
    lat: '33.44838',
    long: '-112.07404',
    img: 'https://static.golfchannel.com/golfpass/locations/img/phoenix.webp',
    location: 'Phoenix, AZ',
  },
  {
    lat: '36.17497',
    long: '-115.13722',
    img: 'https://static.golfchannel.com/golfpass/locations/img/las-vegas.webp',
    location: 'Las Vegas, NV',
  },
  {
    lat: '28.53834',
    long: '-81.37924',
    img: 'https://static.golfchannel.com/golfpass/locations/img/orlando.webp',
    location: 'Orlando, FL',
  },
  {
    lat: '33.8303',
    long: '-116.54529',
    img: 'https://static.golfchannel.com/golfpass/locations/img/palm-springs.webp',
    location: 'Palm Springs, CA',
  },
  {
    lat: '43.7182713',
    long: '-79.377706',
    img: 'https://static.golfchannel.com/golfpass/locations/img/toronto.webp',
    location: 'Toronto, CN',
  },
  {
    lat: '32.71533',
    long: '-117.15726',
    img: 'https://static.golfchannel.com/golfpass/locations/img/san-diego.webp',
    location: 'San Diego, CA',
  },
  {
    lat: '32.22174',
    long: '-110.9264',
    img: 'https://static.golfchannel.com/golfpass/locations/img/tucson.webp',
    location: 'Tucson, AZ',
  },
  {
    lat: '34.05223',
    long: '-118.24368',
    img: 'https://static.golfchannel.com/golfpass/locations/img/los-angeles.webp',
    location: 'Los Angeles, CA',
  },
  {
    lat: '37.77493',
    long: '-122.41942',
    img: 'https://static.golfchannel.com/golfpass/locations/img/san-fran.webp',
    location: 'San Francisco, CA',
  },
  {
    lat: '33.749',
    long: '-84.38798',
    img: 'https://static.golfchannel.com/golfpass/locations/img/atlanta.webp',
    location: 'Atlanta, GA',
  },
];

let destinations;

const requestDestinations = async () => {
  return axios({
    json: true,
    method: 'get',
    url: 'https://static.golfchannel.com/golfpass/locations/destinations.json',
  })
    .then(function (response) {
      try {
        return response.data;
      } catch (e) {
        return defaultDestinations;
      }
    })
    .catch(function (response) {
      return defaultDestinations;
    });
};

export default {
  getDestinations: async () => {
    if (destinations) {
      return destinations;
    }

    destinations = await requestDestinations();
    return destinations;
  },
};
