import { Disclosure } from '@headlessui/react';
import { Logo } from './Logo';

export const Nav = () => {
  return (
    <Disclosure as="nav" className="bg-black">
      <div className="mx-auto max-w-full sm:px-6 lg:px-8 mx-1">
        <div className="relative flex h-16 items-center justify-between">
          <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex flex-shrink-0 items-center">
              <a href="https://www.golfpass.com" target="_blank" rel="noreferrer">
                <Logo classes="w-60 h-7" imageName="golfpass-primary-logo-rgb-rev.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Disclosure>
  );
};

export default Nav;
