export const StepTile = ({ tile }) => {
  return (
    <li className="flex justify-between p-6 bg-gradient-to-r from-gp-light-black to-gp-med-black">
      <div className="text-lg">{tile.id}</div>
      <div className="">{tile.img}</div>
      <div>
        <p className="w-40">
          <span className="block text-5xl">{tile.title}</span>
          {tile.desc}
        </p>
      </div>
    </li>
  );
};

export default StepTile;
