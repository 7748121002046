/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
/* eslint-disable no-debugger */
import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { formatInTimeZone } from 'date-fns-tz';
import { TeeTimeSelector } from '../TeeTimeFilters/TeeTimeResults/TeeTimeSelector';

export const ViewMoreModal = ({
  viewMore,
  setViewMore,
  result,
  fetchInvoiceData,
  selectedDate,
  startDate,
  endDate,
}) => {
  const [teeTimeDates, setTeeTimeDates] = useState(null);
  const timeZone = 'America/New_York';

  useEffect(() => {
    if (selectedDate === undefined || selectedDate === 'Invalid Date' || selectedDate === null) {
      setTeeTimeDates(
        `${formatInTimeZone(new Date(startDate), timeZone, 'LLLL, do')} -
         ${formatInTimeZone(new Date(endDate), timeZone, 'LLLL, do')}`,
      );
    } else {
      setTeeTimeDates(formatInTimeZone(new Date(selectedDate), timeZone, 'LLLL, do'));
    }
  }, [selectedDate]);

  return (
    <Transition.Root show={viewMore} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setViewMore}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => setViewMore(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-8 sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h2" className="mb-10 text-3xl font-semibold leading-6 text-gray-900">
                      All Tee Times
                    </Dialog.Title>
                    <div className="mt-2 mb-6">
                      <h3 className="text-xl font-medium text-gray-900">{result.Name}</h3>
                      <p className="mb-8 text-lg text-gray-600">
                        {result.Address.Line1}&nbsp;
                        {result.Address.Line2}&nbsp;
                        {result.Address.Line3}&nbsp;
                        {result.Address.City},&nbsp;{result.Address.StateProvinceCode}&nbsp;{result.Address.PostalCode}
                      </p>
                      <div className="mb-6">
                        <time className="text-xl font-semibold">{teeTimeDates}</time>
                      </div>

                      <ul className="grid grid-cols-3 gap-2 md:grid-cols-6">
                        {result.teetimes.map((tee, index) => (
                          <li key={index} className="shadow-sm">
                            <TeeTimeSelector
                              fetchInvoiceData={fetchInvoiceData}
                              result={result}
                              tee={tee}
                              viewMore={viewMore}
                              setViewMore={setViewMore}
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ViewMoreModal;
