import { pageView } from '../../../utils/pageView';

export const TeeTimeSelector = ({ tee, result, fetchInvoiceData, setViewMore, player }) => {
  const toRegularTime = (militaryTime) => {
    const [hours, minutes] = militaryTime.split(':');
    return `${hours > 12 ? hours - 12 : hours}:${minutes}${hours >= 12 ? ' PM' : ' AM'}`;
  };

  return (
    <button
      type="button"
      className="text-center rounded-md shadow-lg "
      onClick={() => {
        fetchInvoiceData(tee.FacilityID, tee.DisplayRate.TeeTimeRateID, result, tee, player);
        pageView('Tee Time Selected', { tee, result });
        setViewMore(false);
      }}
    >
      <div className="bg-gp-blue text-sm text-white p-1.5 rounded-t-md ">{toRegularTime(tee.Time.split('T')[1])}</div>
      <p className="bg-white text-gp-med-black text-xs lg:text-sm py-1.5 px-2 rounded-b-md whitespace-nowrap">
        {tee.PlayerRule} Players
      </p>
    </button>
  );
};

export default TeeTimeSelector;
