/* eslint-disable no-debugger, no-console, array-callback-return,
no-unused-expressions, no-unused-vars, react/no-array-index-key */
import { useState } from 'react';
import { MagnifyingGlass } from 'react-loader-spinner';
import { TeeTimeTile } from './TeeTimeTile';
import { Pagination } from '../../Global/Pagination';

export const TeeTimeResults = ({ data, fetchInvoiceData, selectedDate, startDate, endDate, isLoading, player }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data !== null ? data.slice(indexOfFirstRecord, indexOfLastRecord) : null;
  const nPages = data !== null ? Math.ceil(data.length / recordsPerPage) : null;

  return (
    <div>
      {isLoading && (
        <div className="flex items-center justify-center mt-40">
          <MagnifyingGlass
            visible
            height="80"
            width="80"
            ariaLabel="MagnifyingGlass-loading"
            wrapperStyle={{}}
            wrapperClass="MagnifyingGlass-wrapper"
            glassColor="#c0efff"
            color="#FFF"
          />
        </div>
      )}
      {!isLoading && currentRecords?.length > 0 && (
        <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-6 gap-x-6">
          {currentRecords.map((result, index) => (
            <TeeTimeTile
              fetchInvoiceData={fetchInvoiceData}
              key={index}
              result={result}
              selectedDate={selectedDate}
              startDate={startDate}
              endDate={endDate}
              player={player}
            />
          ))}
        </ul>
      )}
      {!isLoading && currentRecords?.length <= 0 && (
        <div className="flex justify-center mt-10">
          <h2 className="block text-lg text-center text-white">
            There are no tee times matching your current filter options.
            <br /> Try adjusting your filter options to view other tee times available.
          </h2>
        </div>
      )}

      {nPages > 1 ? <Pagination nPages={nPages} currentPage={currentPage} setCurrentPage={setCurrentPage} /> : ''}
    </div>
  );
};

export default TeeTimeResults;
