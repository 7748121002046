/* eslint-disable */
import { useState } from 'react';
import Slider from 'rc-slider/lib/index';
import { Title } from './Title';
import 'rc-slider/assets/index.css';

export const TimeRangeSlider = ({ handleTimeChange }) => {
  const [format, setFormat] = useState(12);
  const [maxValue, setMaxValue] = useState(21);
  const [minValue, setMinValue] = useState(5);
  const [value, setValue] = useState([5, 21]);

  const toRegularTime = (militaryTime) => {
    const hours = militaryTime;
    const minutes = '00';
    return `${hours > 12 ? hours - 12 : hours}:${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;
  };

  const minuteToTime = (val) => {
    let hours = val,
      minutes = '00',
      ampm = null;

    if (format == 12) {
      ampm = 'AM';
      if (hours >= 12) {
        if (hours == 12) {
          hours = hours;
        } else {
          hours = hours;
        }
        ampm = 'PM';
      }
      if (hours == 0) {
        hours = 12;
      }
    }

    return { hours: hours, minutes: minutes, am_pm: ampm };
  };

  const onChange = (val) => {
    let start = minuteToTime(val[0]);
    let end = minuteToTime(val[1]);
    let nStart = start.hours <= 9 ? `0${start.hours}:00` : start.hours + ':' + start.minutes;
    let nEnd = end.hours <= 9 ? `0${end.hours}:00` : end.hours + ':' + end.minutes;

    setValue([val[0], val[1]]);
    handleTimeChange({ start: nStart, end: nEnd });
  };

  return (
    <div className="pt-5 pb-7">
      <div className="flex items-baseline justify-between">
        <Title label="Time" />
        <small className='ml-6 xl:ml-0'>{`${toRegularTime(value[0])} - ${toRegularTime(value[1])}`}</small>
      </div>

      <div className=''>
        <Slider
          defaultValue={[minValue, maxValue]}
          value={[value[0], value[1]]}
          step={1}
          allowCross={false}
          range
          count={1}
          min={5}
          max={21}
          onChange={(val) => onChange(val)}
          trackStyle={[{ backgroundColor: '#189BD7' }, { backgroundColor: '#189BD7' }]}
          handleStyle={[
            {
              height: '16px',
              width: '16px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.29)',
              border: '1px solid #e4e4e4',
            },
            {
              height: '16px',
              width: '16px',
              boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.29)',
              border: '1px solid #e4e4e4',
            },
          ]}
          railStyle={{ backgroundColor: '#eeeeee' }}
        />
      </div>
    </div>
  );
};

export default TimeRangeSlider;
