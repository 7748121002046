/* eslint-disable no-shadow, no-unused-vars */
import Slider from 'rc-slider/lib/index';
import { useState } from 'react';
import { Title } from '../../Global/Title';
import 'rc-slider/assets/index.css';

export const Distance = ({ debouncedEventHandler, distanceVal }) => {
  const [value, setValue] = useState(distanceVal);

  const setDistanceValAll = (val) => {
    setValue(val);
    debouncedEventHandler(val);
  };

  return (
    <div className="pt-5 pb-7">
      <div className="flex items-baseline justify-between">
        <Title label="Distance" />
        <p className="text-xs">{value}mi</p>
      </div>
      <Slider
        defaultValue={50}
        value={value}
        min={5}
        max={100}
        onChange={(val) => setDistanceValAll(val)}
        trackStyle={[{ backgroundColor: '#189BD7' }, { backgroundColor: '#189BD7' }]}
        handleStyle={[
          {
            height: '16px',
            width: '16px',
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.29)',
            border: '1px solid #e4e4e4',
          },
          {
            height: '16px',
            width: '16px',
            boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.29)',
            border: '1px solid #e4e4e4',
          },
        ]}
        railStyle={{ backgroundColor: '#eeeeee' }}
      />
    </div>
  );
};

export default Distance;
