import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './styles/index.css';
import TagManager from 'react-gtm-module';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MainNav from './components/Global/Nav';
import MainFooter from './components/Global/Footer';

const tagManagerArgs = {
  gtmId: window.globals.gtmId,
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <MainNav />
    <App />
    <MainFooter />
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
