/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, no-unused-vars */
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import { addWeeks } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import FormDataContext from '../../FormDataContext';
import DestinationsService from '../../services/destinations-service';
import { pageView } from '../../utils/pageView';

export const Destinations = () => {
  const timeZone = 'America/New_York';
  const { setFormData } = useContext(FormDataContext);
  const [destinations, setDestinations] = useState([]);
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const today = formatInTimeZone(new Date(), timeZone, 'yyyy-MM-dd');
  const threeWeeks = addWeeks(new Date(today), 3);
  const fourWeeks = addWeeks(new Date(today), 4);
  const threeWeeksFormatted = formatInTimeZone(threeWeeks, timeZone, 'yyyy-MM-dd');
  const fourWeeksFormatted = formatInTimeZone(fourWeeks, timeZone, 'yyyy-MM-dd');

  useEffect(() => {
    const fetchData = async () => {
      const response = await DestinationsService.getDestinations();
      setDestinations(response);
    };
    fetchData();
  }, []);

  const handleClick = (lat, long, location) => {
    // eslint-disable-next-line
    setFormData({ lat, long, location });
    const updatedFormData = {
      location,
      lat,
      q: 'geo-location',
      proximity: 50,
      long,
      players: 4,
      'sort-by': 'Facilities.Rating',
      'sort-direction': 'desc',
      'date-max': fourWeeksFormatted,
      'date-min': threeWeeksFormatted,
      take: 15,
      'trade-only': true,
      expand: 'Facilities.RatingSummary|Facilities',
    };
    pageView('destinations', updatedFormData);
    setSearchParams(updatedFormData);
    navigate({
      pathname: '/results',
      search: `?${createSearchParams(updatedFormData)}`,
    });
  };

  return (
    <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-5 xl:gap-x-8">
      {destinations.map((destination) => (
        <div
          onClick={() => handleClick(destination.lat, destination.long, destination.location)}
          key={`destination-${destination.lat}-${destination.long}`}
        >
          <li className="relative rounded-lg bg-gp-light-black">
            <div className="block w-full overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
              <img
                src={`${destination.img}`}
                alt=""
                className="object-cover pointer-events-none group-hover:opacity-75"
              />
              <button type="button" className="absolute inset-0 focus:outline-none">
                <span className="sr-only">View details for {destination.location}</span>
              </button>
            </div>
            <p className="block p-3 text-sm font-medium text-white rounded-b-lg pointer-events-none">
              {destination.location}
            </p>
          </li>
        </div>
      ))}
    </ul>
  );
};

export default Destinations;
