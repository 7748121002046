import { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';

export const DateRangePicker = ({ setArrive, setDepart }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    setArrive(new Date(`${startDate}`));
    setDepart(new Date(`${endDate}`));
  }, [startDate, endDate]);

  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        minDate={new Date()}
        endDate={endDate}
        placeholderText="Check In"
      />
      <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={new Date()}
        placeholderText="Check Out"
      />
    </>
  );
};

export default DateRangePicker;
