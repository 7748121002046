/* eslint-disable jsx-a11y/anchor-is-valid */
import { ArrowCircleLeftIcon, ArrowCircleRightIcon } from '@heroicons/react/solid';

export const Pagination = ({ nPages, currentPage, setCurrentPage }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const nextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <nav className="my-10 flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <a
          href="#"
          onClick={prevPage}
          className="inline-flex items-center border-t-2 border-transparent pt-4 pr-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          <ArrowCircleLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
          Previous
        </a>
      </div>
      {pageNumbers.map((pgNumber) => (
        <div
          key={pgNumber}
          className={`page-item hidden md:-mt-px md:flex ${currentPage === pgNumber ? 'active' : ''} `}
        >
          <a
            href="#"
            onClick={() => setCurrentPage(pgNumber)}
            className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
          >
            {pgNumber}
          </a>
        </div>
      ))}
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          href="#"
          onClick={nextPage}
          className="inline-flex items-center border-t-2 border-transparent pt-4 pl-1 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          Next
          <ArrowCircleRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
        </a>
      </div>
    </nav>
  );
};

export default Pagination;
