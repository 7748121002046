/* eslint-disable consistent-return */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import { StarIcon } from '@heroicons/react/solid';
import { useViewport } from '../../../hooks/useViewport';
import { TeeTimeSelector } from './TeeTimeSelector';
import { TeeTimeViewMore } from './TeeTimeViewMore';

export const TeeTimeTile = ({ result, fetchInvoiceData, selectedDate, startDate, endDate, player }) => {
  const { width } = useViewport();
  const [viewMore, setViewMore] = useState(false);
  let teeTimeCount = 3;
  const show2TeeTimes = result.teetimes.slice(0, 2);
  const show3TeeTimes = result.teetimes.slice(0, 3);
  const show4TeeTimes = result.teetimes.slice(0, 4);
  const show5TeeTimes = result.teetimes.slice(0, 5);
  const viewMoreCount = result.teetimes.length - teeTimeCount;
  const rating = Math.round(result.RatingSummary.Value.AverageRating * 10) / 10;

  const initialTeeTimes = () => {
    if (width <= 580) {
      return show3TeeTimes;
    }
    if (width <= 640) {
      teeTimeCount = 4;
      return show4TeeTimes;
    }
    if (width <= 768) {
      teeTimeCount = 5;
      return show5TeeTimes;
    }
    if (width <= 1024) {
      teeTimeCount = 3;
      return show3TeeTimes;
    }
    if (width <= 1280) {
      teeTimeCount = 4;
      return show4TeeTimes;
    }
    if (width <= 1540) {
      teeTimeCount = 2;
      return show2TeeTimes;
    }
    if (width >= 1541) {
      teeTimeCount = 3;
      return show3TeeTimes;
    }
  };

  const handleViewMore = () => {
    setViewMore(!viewMore);
  };

  return (
    <li key={result.ID} className="relative text-white rounded-md bg-gp-light-black">
      <div className="block w-full mb-3 overflow-hidden bg-gray-100 rounded-t-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
        <img
          src={result.FullSizeImagePath}
          alt="course"
          className="object-cover pointer-events-none group-hover:opacity-75"
        />
      </div>
      <div className="px-2 pb-3">
        <div className="flex justify-between">
          <div>
            <h4 className="mb-1 text-lg font-semibold leading-none line-clamp-1">{result.Name}</h4>
            <p className="mb-4 line-clamp-1">
              {result.Address.Line1}, &nbsp;
              {result.Address.City}, &nbsp;
              {result.Address.StateProvinceCode}
            </p>
          </div>
          <div className="flex items-center">
            {rating} <StarIcon className="h-4 ml-1 text-white" />
          </div>
        </div>
        <div>
          <ul className="flex space-x-2 align-center">
            {initialTeeTimes().map((tee, index) => (
              <li key={index} className="">
                <TeeTimeSelector
                  fetchInvoiceData={fetchInvoiceData}
                  result={result}
                  tee={tee}
                  setViewMore={setViewMore}
                  player={player}
                />
              </li>
            ))}
            {result.teetimes.length > 3 && (
              <li>
                <TeeTimeViewMore
                  handleViewMore={handleViewMore}
                  viewMoreCount={viewMoreCount}
                  result={result}
                  fetchInvoiceData={fetchInvoiceData}
                  viewMore={viewMore}
                  setViewMore={setViewMore}
                  selectedDate={selectedDate}
                  startDate={startDate}
                  endDate={endDate}
                />
              </li>
            )}
          </ul>
        </div>
      </div>
    </li>
  );
};

export default TeeTimeTile;
