/* eslint-disable consistent-return */
/* eslint-disable no-debugger, no-console, no-shadow,no-unused-expressions,
array-callback-return, no-unused-vars, import/no-extraneous-dependencies,
no-unneeded-ternary, no-undef, no-sequences, no-else-return, spaced-comment*/
import { useState, useEffect, useContext, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { addWeeks, isSameDay, addDays, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useSearchParams } from 'react-router-dom';
import { getInvoice, getTeeTimes, getLocation } from '../services/provider';
import { FilterColumn } from '../components/TeeTimeFilters/FilterColumn/FilterColumn';
import { SortBy } from '../components/TeeTimeFilters/SortBy/SortBy';
import { TeeTimeResults } from '../components/TeeTimeFilters/TeeTimeResults/TeeTimeResults';
import { DateCarousel } from '../components/TeeTimeFilters/DateCarousel/DateCarousel';
import { ErrorModal } from '../components/Modal/ErrorModal';
import { Modal } from '../components/Modal/Modal';
import FormDataContext from '../FormDataContext';
import { FilterButton } from '../components/Global/FilterButton';
import { pageView } from '../utils/pageView';

export const TeeTimesPage = () => {
  // Set up const used throughout the page
  const { formData, setFormData } = useContext(FormDataContext);
  const [isLoading, setIsLoading] = useState(null);
  const [data, setData] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const timeZone = 'America/New_York';
  const [isFilterActive, setIsFilterActive] = useState(true);
  const [isAvailable, setIsAvailable] = useState(null);
  const [distanceVal, setDistanceVal] = useState(50);
  const [carouselDefault, setCarouselDefault] = useState(null);
  const [result, setResult] = useState(null);
  const [teeModal, setTeeModal] = useState(null);
  const [defaultData, setDefaultData] = useState(null);
  const playerCondition = formData.players ? parseInt(formData.players, 10) : 4;
  const [player, setPlayer] = useState(playerCondition);
  const [selectedDate, setSelectedDate] = useState(null);
  const [, setIsSingle] = useState(false);
  const [sortBy, setSortBy] = useState('Facilities.Rating');
  const [sortDirection, setSortDirection] = useState('desc');
  const [timeRange, setTimeRange] = useState({
    start: '05:00',
    end: '21:00',
  });
  const today = formatInTimeZone(new Date(), timeZone, 'yyyy-MM-dd');
  const threeWeeks = addWeeks(new Date(today), 3);
  const fourWeeks = addWeeks(new Date(today), 4);
  const threeWeeksFormatted = formatInTimeZone(new Date(threeWeeks), timeZone, 'yyyy-MM-dd');
  const fourWeeksFormatted = formatInTimeZone(new Date(fourWeeks), timeZone, 'yyyy-MM-dd');
  const dateStartCondition =
    formData['date-min'] === 'Invalid Date' || formData['date-min'] === undefined
      ? new Date(threeWeeksFormatted)
      : new Date(formData['date-min']);
  const dateEndCondition =
    formData['date-max'] === 'Invalid Date' || formData['date-max'] === undefined
      ? new Date(fourWeeksFormatted)
      : new Date(formData['date-max']);
  const [startDate, setStartDate] = useState(new Date(dateStartCondition));
  const [endDate, setEndDate] = useState(new Date(dateEndCondition));
  const isBetweenTime = (start, end, time) => {
    const timeSplit = time.split(':');
    const timeConcat = `${timeSplit[0]}:${timeSplit[1]}`;

    if (timeConcat > start && timeConcat < end) {
      return true;
    }
    return false;
  };
  const notAvailableTimesIDs = [];
  const latParam = searchParams.get('lat');
  const locationParam = searchParams.get('location');
  const longParam = searchParams.get('long');
  const distanceParam = searchParams.get('proximity');
  const playerParam = searchParams.get('players');
  const arrivalParam = searchParams.get('date-min');
  const departureParam = searchParams.get('date-max');
  const sortByParam = searchParams.get('sort-by');
  const sortDirectionParam = searchParams.get('sort-direction');
  const [typeAheadValue, setTypeAheadValue] = useState('');
  const [teeTimeDates, setTeeTimeDates] = useState([]);

  const availableTeeTimeDates = (data) => {
    const mappedDates = data
      .map((item) => item.teetimes)
      .flat()
      .map((teetime) => {
        return teetime.Time.split('T')[0];
      });

    // remove duplicates
    const uniqueTeeTimeDates = mappedDates.filter((date, index) => {
      return mappedDates.indexOf(date) === index;
    });

    // format to Num for sorting
    const formatToNum = uniqueTeeTimeDates.map((date) => {
      return Number(date.replace(/-/g, ''));
    });

    // sort array so first date is index 0
    const sortedTeeTimeDates = formatToNum.sort((a, b) => a - b);

    return sortedTeeTimeDates;
  };

  // *selectedArg - if date is selected from date carousel, will pass date param - null or date
  // *playerARg - should always be passed once players Filter is selected
  const filterAllData = (defaultDataArg, selectedArg, playerArg) => {
    const filteredData = defaultDataArg
      .map((item) => {
        const filteredTeeTimes = item.teetimes.filter((data) => {
          const splitTime = data.Time.split('T')[1];
          let playerChange = [];
          switch (data.PlayerRule) {
            case '1':
              playerChange.push(1);
              break;
            case '1, 4':
              playerChange.push(1, 4);
              break;
            case '1 - 4':
              playerChange.push(1, 2, 3, 4);
              break;
            case '4':
              playerChange.push(4);
              break;
            case '1, 3':
              playerChange.push(1, 3);
              break;
            case '1, 3 - 4':
              playerChange.push(1, 3, 4);
              break;
            case '1 - 2':
              playerChange.push(1, 2);
              break;
            case '1 - 2, 4':
              playerChange.push(1, 2, 4);
              break;
            case '1 - 3':
              playerChange.push(1, 2, 3);
              break;
            case '3':
              playerChange.push(3);
              break;
            case '3 - 4':
              playerChange.push(3, 4);
              break;
            case '2':
              playerChange.push(2);
              break;
            case '2, 4':
              playerChange.push(2, 4);
              break;
            case '2 - 3':
              playerChange.push(2, 3);
              break;
            case '2 - 4':
              playerChange.push(2, 3, 4);
              break;
            default:
              playerChange = 'Unknown';
              break;
          }

          const myPlayer = playerArg ? playerArg : player;
          const myDate = selectedArg !== null ? selectedArg : selectedDate;
          if (selectedArg === null) {
            return isBetweenTime(timeRange.start, timeRange.end, splitTime) && playerChange.includes(myPlayer);
          } else {
            const dataDate = new Date(data.Time);
            const splitDate = formatInTimeZone(dataDate, timeZone, 'yyyy-MM-dd');
            return (
              isBetweenTime(timeRange.start, timeRange.end, splitTime) &&
              isSameDay(parseISO(splitDate), parseISO(myDate)) &&
              playerChange.includes(myPlayer)
            );
          }
        });
        return {
          ...item,
          teetimes: filteredTeeTimes,
        };
      })
      .filter((item) => item.teetimes.length > 0);

    setData(filteredData);
  };

  useEffect(() => {
    if (formData.location) {
      setTypeAheadValue(formData.location);
    } else {
      const locationParams = {
        lat: latParam,
        long: longParam,
      };

      getLocation(locationParams).then((response) => {
        const location = response.displayName;
        setTypeAheadValue(location);
      });
    }
  }, []);

  const useHandleTypeAheadChange = (name, lat, long) => {
    setTypeAheadValue(name);
  };

  const handleTimeChange = (value) => {
    setTimeRange(value);
    filterAllData(defaultData, selectedDate, player);
  };

  const handleDistanceChange = (value) => {
    setDistanceVal(value);
  };

  const debouncedEventHandler = useMemo(() => debounce(handleDistanceChange, 300), [distanceVal]);

  const handleDateCarouselChange = (selectedArg) => {
    setIsSingle(true);
    setSelectedDate(selectedArg);
    filterAllData(defaultData, selectedArg, player);
  };

  const handlePlayerChange = (playerArg) => {
    setPlayer(playerArg);
    filterAllData(defaultData, selectedDate, playerArg);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end !== null) {
      setIsSingle(false);
    }
  };

  const handleFilterClick = () => {
    setIsFilterActive((current) => !current);
  };

  const setSortByAll = (dataArg, directionArg) => {
    setSortBy(dataArg);
    setSortDirection(directionArg);
  };

  // always called when data changes for endDate, sortBy, distanceVal, sortDirection, typeAheadValue
  const fetchTeeTimeData = async (passedParams) => {
    // eslint-disable-next-line

    setIsLoading(true);
    if (endDate !== null) {
      const firstDates = [];
      let isArrivalDate = null;
      await getTeeTimes(passedParams).then((response) => {
        response.forEach((element) => {
          const arrayFirst = element.teetimes[0].Time;
          const dataDate = new Date(arrayFirst);
          const dataDateFormat = formatInTimeZone(dataDate, timeZone, 'yyyy-MM-dd');
          firstDates.push(dataDateFormat);
        });
        firstDates.sort((a, b) => Date.parse(b) - Date.parse(a));
        const formattedSplitted = firstDates[0];
        isArrivalDate = isSameDay(parseISO(formattedSplitted), parseISO(startDate))
          ? addDays(parseISO(formattedSplitted), 1)
          : formattedSplitted;
        setCarouselDefault(isArrivalDate);
        setSelectedDate(isArrivalDate);
        setData(response);
        setDefaultData(response);
        setIsLoading(false);
        setTeeTimeDates(availableTeeTimeDates(response));
        filterAllData(response, isArrivalDate, formData.player || player);
      });
    }
  };

  const fetchInvoiceData = async (facId, rateId, result, tee, player) => {
    const invoiceParams = {
      facilityId: facId,
      teeTimeRateId: rateId,
      playerCount: player,
    };

    await getInvoice(invoiceParams).then((response) => {
      if (response.ErrorCode === 88 || response.ErrorCode === 0) {
        setIsAvailable(false);
        notAvailableTimesIDs.push(id);
      } else {
        setIsAvailable(true);
        setResult(result);
        setTeeModal(tee);
      }
    });
  };

  const setSearchAndTeeTimes = () => {
    setSearchParams({
      lat: formData.lat || latParam,
      long: formData.long || longParam,
      q: 'geo-location',
      players: player || playerParam,
      location: typeAheadValue,
      'sort-by': sortBy || sortByParam,
      'sort-direction': sortDirection || sortDirectionParam,
      'date-min': formatInTimeZone(startDate, timeZone, 'yyyy-MM-dd') || arrivalParam,
      'date-max': formatInTimeZone(endDate, timeZone, 'yyyy-MM-dd') || departureParam,
      proximity: distanceVal || distanceParam,
      take: 200,
      'trade-only': true,
      expand: 'Facilities.RatingSummary|Facilities',
    });
    let newParams = null;
    newParams = {
      q: 'geo-location',
      latitude: formData.lat || latParam,
      longitude: formData.long || longParam,
      proximity: distanceVal || distanceParam,
      'date-min': formatInTimeZone(startDate, timeZone, 'yyyy-MM-dd') || arrivalParam,
      'date-max': formatInTimeZone(endDate, timeZone, 'yyyy-MM-dd') || departureParam,
      'sort-by': sortBy || sortByParam,
      'sort-direction': sortDirection || sortDirectionParam,
      take: 200,
      players: player || playerParam,
      'trade-only': true,
      expand: 'Facilities.RatingSummary|Facilities',
    };
    fetchTeeTimeData(newParams);
    pageView('Change to Search', { ...newParams, location: formData.location });
  };

  // Will update anytime the data changes for the passed in values
  useEffect(() => {
    if (endDate !== null) {
      setSearchAndTeeTimes();
    }
  }, [endDate, sortBy, distanceVal, sortDirection, typeAheadValue]);

  return (
    <section className="min-h-screen pb-20 bg-gp-black">
      <div
        className="flex max-h-full mb-6 bg-no-repeat bg-cover h-60 bg-tee-times"
        style={{
          backgroundImage: `url(${window.globals.publicPath}/images/results-lede.jpg)`,
        }}
      >
        <div className="p-6 mt-16 sm:mt-24">
          <div className="mx-auto max-w-7xl">
            <div className="lg:grid lg:grid-cols-12 lg:gap-8">
              <div className="text-white px-18 sm:px-18 md:max-w-2xl md:mx-auto lg:col-span-6 lg:items-center">
                <h1 className="text-4xl font-bold text-white sm:text-3xl md:text-4xl">
                  Golf trip to {formData.location?.length > 0 ? formData.location : locationParam}
                </h1>
                <h2 className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  {endDate !== null
                    ? `
                      ${formatInTimeZone(new Date(startDate), timeZone, 'MMM dd')} -
                      ${formatInTimeZone(new Date(endDate), timeZone, 'MMM dd, yyyy')}`
                    : ' '}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="container p-6 mx-auto xl:p-0">
        <FilterButton
          label="Trip Details"
          display="lg:hidden"
          handleClick={handleFilterClick}
          isActive={isFilterActive}
        />
        <div className="xl:flex">
          <div
            className={` ${
              isFilterActive ? '' : 'hidden'
            } lg:block w-full mb-6 mr-5 text-white rounded-md xl:mb-0 xl:w-1/4 bg-gp-light-black pt-4 px-7 pb-7`}
          >
            <FilterColumn
              handleDateChange={handleDateChange}
              startDate={startDate}
              distanceVal={distanceVal}
              player={player}
              endDate={endDate}
              typeAheadValue={typeAheadValue}
              handlePlayerChange={handlePlayerChange}
              debouncedEventHandler={debouncedEventHandler}
              handleTimeChange={handleTimeChange}
              handleTypeAheadChange={useHandleTypeAheadChange}
            />
          </div>
          <div className="xl:w-3/4">
            <div className="items-end justify-between px-5 py-4 mb-10 rounded-md lg:flex md:mb-4 bg-gp-light-black">
              <div className="px-5 mb-4 lg:w-3/4 lg:mb-0 sm-hidden">
                <DateCarousel
                  startDate={startDate}
                  endDate={endDate}
                  carouselDefault={carouselDefault}
                  teeTimeDates={teeTimeDates}
                  formData={formData}
                  handleDateCarouselChange={handleDateCarouselChange}
                />
              </div>
              <div className="lg:w-1/4 sm-hidden">
                <SortBy setSortByAll={setSortByAll} />
              </div>
              <div className="mb-4 lg:w-1/4 lg-hidden">
                <SortBy setSortByAll={setSortByAll} />
              </div>
              <div className="px-5 mb-4 lg:w-3/4 lg:mb-0 lg-hidden">
                <DateCarousel
                  startDate={startDate}
                  endDate={endDate}
                  carouselDefault={carouselDefault}
                  teeTimeDates={teeTimeDates}
                  formData={formData}
                  handleDateCarouselChange={handleDateCarouselChange}
                />
              </div>
            </div>
            <div>
              {isAvailable === false ? <ErrorModal setIsAvailable={setIsAvailable} isAvailable={isAvailable} /> : null}
              {isAvailable === true ? (
                <Modal
                  startDate={formatInTimeZone(new Date(startDate), timeZone, 'yyyy-MM-dd')}
                  endDate={formatInTimeZone(new Date(endDate), timeZone, 'yyyy-MM-dd')}
                  player={player}
                  setIsAvailable={setIsAvailable}
                  teeModal={teeModal}
                  result={result}
                />
              ) : null}
              <TeeTimeResults
                fetchInvoiceData={fetchInvoiceData}
                data={data}
                selectedDate={selectedDate}
                startDate={startDate}
                endDate={endDate}
                isLoading={isLoading}
                player={player}
              />
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default TeeTimesPage;
