import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';

export const FilterButton = ({ label, handleClick, isActive, display }) => {
  return (
    <button
      type="button"
      className={` ${display} flex items-center justify-between w-full p-4 mb-6 text-lg text-white border rounded-md cursor-pointer border-gp-blue bg-gp-light-black`}
      onClick={handleClick}
    >
      {label}
      {isActive ? <ChevronUpIcon className="h-6" /> : <ChevronDownIcon className="h-6" />}
    </button>
  );
};

export default FilterButton;
