export const pageView = (eventName, data) => {
  const location = document.location.origin + document.location.pathname + document.location.search;
  window.dataLayer.push({
    event: eventName,
    page: {
      url: location,
    },
    data: data || null,
  });
};

export default pageView;
