// TODO: Add Props for Text, Color and maybe size
export const Button = ({ href, label }) => {
  return (
    <button
      className="w-full p-4 text-xl font-semibold text-white rounded-sm bg-bs-red"
      type="submit"
      form="bookseats-search"
      value="submit"
      href={href}
    >
      {label}
    </button>
  );
};

export default Button;
