/* eslint-disable no-unused-vars */
import DatePicker from 'react-datepicker';
import { Title } from './Title';

export const OneDateRangePicker = ({ startDate, endDate, handleDateChange }) => {
  return (
    <div className="pb-7">
      <Title label="Travel Dates" />
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        startDate={startDate}
        minDate={new Date()}
        endDate={endDate}
        selectsRange
      />
    </div>
  );
};

export default OneDateRangePicker;
