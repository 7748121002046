import { StepTile } from './StepTile';

const tiles = [
  {
    id: 1,
    title: 'Find',
    img: <img className="h-24" src={`${window.globals.publicPath}/images/find-icon.svg`} alt="" />,
    desc: 'a golf destination',
  },
  {
    id: 2,
    title: 'Pick',
    img: <img className="h-24" src={`${window.globals.publicPath}/images/pick-icon.svg`} alt="" />,
    desc: 'your Tee Time',
  },
  {
    id: 3,
    title: 'Save',
    img: <img className="h-24" src={`${window.globals.publicPath}/images/save-icon.svg`} alt="" />,
    desc: 'on hotels and flights through BookSeats',
  },
];

export const Steps = () => {
  return (
    <div>
      <ul className="grid grid-cols-1 gap-4 text-white lg:space-x-4 lg:grid-cols-3">
        {tiles.map((tile) => (
          <StepTile tile={tile} key={tile.id} />
        ))}
      </ul>
    </div>
  );
};

export default Steps;
