/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events, 
jsx-a11y/anchor-is-valid */

import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { CourseTile } from './CourseTile';
import { Button } from '../Global/Button';
import { Logo } from '../Global/Logo';
import { pageView } from '../../utils/pageView';

export const Modal = ({ setIsAvailable, teeModal, result, player, startDate, endDate }) => {
  const [open, setOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    // eslint-disable-next-line
    pageView('Continue Button Clicked');
    window.location.href = `${window.globals.bookseatsUrl}golf-package?fid=${teeModal.FacilityID}&ttid=${teeModal.DisplayRate.TeeTimeRateID}&golfers=${player}&event_date=${teeModal.Time}&departure_date=${startDate}&return_date=${endDate}`;
  };
  return (
    <Transition.Root show={!open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-3xl sm:w-full sm:p-6">
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => {
                      // eslint-disable-next-line
                      pageView('Continue to Travel Plans - Modal Close Clicked');
                      setOpen(false);
                      setIsAvailable(null);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
                <Logo imageName="gp-primary-logo-rgb-pos.svg" classes="h-7 mt-8" />
                <div className="mt-8 sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-3xl font-bold leading-6 text-gray-900">
                      Continue to Travel Plans
                    </Dialog.Title>
                    <div className="mt-4 mb-6">
                      <p>
                        To find your hotel and/or flights, secure your tee time, and finalize your trip, click
                        “Continue” to be transferred to our partner, BookSeats.com.&nbsp;
                        <a
                          href="https://bookseats.com/how-it-works"
                          className="underline text-gp-blue"
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Learn more
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="p-6 border border-gray-300 border-solid rounded-md">
                  <CourseTile teeModal={teeModal} result={result} player={player} />
                </div>

                <div className="justify-between mt-7 sm:flex">
                  <div className="grid-cols-6">
                    <Logo imageName="bookseats-logo.svg" classes="w-48" />
                  </div>
                  <div className="grid-cols-6 mt-5 text-center sm:mt-0">
                    <a onClick={(e) => handleClick(e)}>
                      <Button label="Continue" />
                    </a>
                    <small className="text-gray-500">(No charge will be made at this point.)</small>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
