import { createContext } from 'react';

const FormDataContext = createContext({
  location: '',
  lat: null,
  long: null,
  players: Number,
  depart: '',
  arrive: '',
  setFormData: () => {},
});

export default FormDataContext;
